<template>
  <div class="relative md:-m-4" v-bind="$attrs">
    <div
      ref="scrollable"
      class="scrollable flex select-none snap-x snap-mandatory list-none overflow-x-auto whitespace-nowrap md:scroll-p-2 md:p-2"
      :class="{ 'cursor-grab': groups.length > 1 }"
    >
      <div
        v-for="(product, index) in filteredProducts"
        :key="index"
        class="w-1/2 shrink-0 md:w-1/4 md:p-2 [&:nth-child(2n+1)]:snap-start md:[&:nth-child(2n+1)]:snap-align-none md:[&:nth-child(4n+1)]:snap-start"
      >
        <slot name="product" :product="product" :index="index" />
      </div>
    </div>
  </div>

  <div class="mt-6 flex flex-row items-center justify-center md:mt-8">
    <SwiperBullets :groups="groups" :go-to="goTo" />
  </div>
</template>

<script setup lang="ts">
import type { Product as ProductType } from '#root/shared/types/graphql-types';
import useSwiper from './useSwiper';

const props = defineProps({
  products: {
    type: Array as PropType<ProductType[]>,
    required: false,
    default: () => [],
  },
});

// Lazy laoding of products create an array with undefined values
const filteredProducts = computed(() =>
  props.products.filter((product) => product !== undefined)
);

const scrollable = ref<HTMLElement | null>(null);
const { groups, goTo } = useSwiper(scrollable);
</script>

<style scoped>
.scrollable::-webkit-scrollbar {
  display: none;
}
.scrollable {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overscroll-behavior-x: contain;
}
</style>
